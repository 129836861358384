const EVENT_TABLE_OPTIOINS = {
    rowStyle: function(row, index) {
        return {
            classes: 'none'
    };
    },
    formatNoMatches: function() {
        return '-';
    },
    cache: false,
    height: 410,
    striped: true,
    pagination: false,
    pageSize: 10,
    pageList: [5, 10, 25, 50, 100, 200],
    search: false,
    showColumns: false,
    showRefresh: false,
    minimumCountColumns: 2,
    clickToSelect: false,
    singleSelect: true,
    showToggle: false,
    maintainSelected: true,
    sortName: 'created_at',
    sortOrder: 'desc',
    uniqueId: 'guid',
}

const EVENT_TABLE_COLUMS = [
  {
    field: 'close',
    checkbox: true
  },
  {
      field: 'created_at',
      title: '발생일시',
      align: 'right',
      valign: 'top',
      sortable: true,
  }, {
      field: 'is_closed',
      title: '종료',
      align: 'center',
      valign: 'top',
      sortable: true,
  }, {
      field: 'level',
      title: '상태',
      align: 'center',
      valign: 'top',
  }, {
      field: 'site',
      title: '사이트명',
      align: 'center',
      valign: 'top',
      sortable: true,
  }, {
      field: 'device_name',
      title: '디바이스명',
      align: 'center',
      valign: 'top',
      sortable: true,
  }, {
      field: 'sensor_type',
      title: '센서타입',
      align: 'center',
      valign: 'top',
      sortable: true,
  }, {
      field: 'sensor_id',
      title: '센서아이디',
      align: 'center',
      valign: 'top',
      sortable: true,
      visible: false
  }, {
      field: 'device_address',
      title: '위치',
      align: 'center',
      valign: 'top',
      sortable: true
  }, {
      field: 'description',
      title: '상세',
      align: 'center',
      valign: 'top',
      sortable: true
  }, {
      field: 'device_guid',
      title: '디바이스ID',
      align: 'center',
      valign: 'top',
      visible: false
  }, {
      field: 'guid',
      title: '이벤트ID',
      align: 'center',
      valign: 'top',
      visible: false
  }
]


const SRDS_EVENT_TABLE_COLUMS = [
  {
    field: 'close',
    checkbox: true
  },
  {
      field: 'created_at',
      title: '발생시각',
      align: 'right',
      valign: 'top',
      sortable: true,
  },{
    field: 'is_closed',
    title: '종료여부',
    align: 'center',
    valign: 'top',
    sortable: true,
  }, {
    field: 'site',
    title: '사이트명',
    align: 'center',
    valign: 'top',
    sortable: true,
  }, {
    field: 'device_name',
    title: '디바이스명',
    align: 'center',
    valign: 'top',
    sortable: true,
  }, {
    field: 'device_address',
    title: '주소',
    align: 'center',
    valign: 'top',
    sortable: true
  },{
      field: 'level',
      title: '위험등급',
      align: 'center',
      valign: 'top',
  },{
    field: 'sensor_scope',
    title: '사 진',
    align: 'center',
    valign: 'top',
    visible: true
  },{
    field: 'pre_angle_change',
    title: '최초 기울기',
    align: 'center',
    valign: 'top',
    visible: true
  },{
    field: 'angle_change',
    title: '측정 기울기(변화값)',
    align: 'center',
    valign: 'top',
    visible: true
  },{
    field: 'battery',
    title: '배터리 상태',
    align: 'center',
    valign: 'top',
    visible: false
  },{
      field: 'description',
      title: '상세',
      align: 'center',
      valign: 'top',
      sortable: true,
      visible: false
  }
]

const FUMAX_EVENT_TABLE_COLUMNS = [
  {
    field: 'close',
    checkbox: true
  },
  {
      field: 'created_at',
      title: '발생시각',
      align: 'right',
      valign: 'top',
      sortable: true,
  },{
    field: 'is_closed',
    title: '종료여부',
    align: 'center',
    valign: 'top',
    sortable: true,
}, {
    field: 'device_name',
    title: '디바이스명',
    align: 'center',
    valign: 'top',
    sortable: true,
  }, {
    field: 'device_address',
    title: '주소',
    align: 'center',
    valign: 'top',
    sortable: true
  },{
      field: 'level',
      title: '위험등급',
      align: 'center',
      valign: 'top',
  },{
    field: 'sensor_scope',
    title: '사 진',
    align: 'center',
    valign: 'top',
    visible: true
  },{
    field: 'pre_angle_change',
    title: '최초 기울기',
    align: 'center',
    valign: 'top',
    visible: true
  },{
    field: 'angle_change',
    title: '측정 기울기(변화값)',
    align: 'center',
    valign: 'top',
    visible: true
  },{
    field: 'battery',
    title: '배터리 상태',
    align: 'center',
    valign: 'top',
    visible: false
  },{
      field: 'description',
      title: '상세',
      align: 'center',
      valign: 'top',
      sortable: true,
      visible: false
  }
]

const LEVEL_TYPES = {
    "-1": [{
        id: -1,
        name: "전체"
      }, {
        id: 0,
        name: "정상"
      },
      {
        id: 1,
        name: "주의"
      },
      {
        id: 2,
        name: "경고"
      },
      {
        id: 3,
        name: "심각"
      }
    ],
    "1": [{
        id: -1,
        name: "전체"
      }, {
        id: 0,
        name: "좋음"
      },
      {
        id: 1,
        name: "보통"
      },
      {
        id: 2,
        name: "주의"
      },
      {
        id: 3,
        name: "경고"
      }
    ],
    "2": [{
        id: -1,
        name: "전체"
      }, {
        id: 0,
        name: "정상"
      },
      {
        id: 1,
        name: "과다"
      },
      {
        id: 2,
        name: "주의"
      },
      {
        id: 3,
        name: "부족"
      }
    ],
    "12": [{
        id: -1,
        name: "전체"
      }, {
        id: 0,
        name: "정상"
      },
      {
        id: 1,
        name: "과다"
      },
      {
        id: 2,
        name: "주의"
      },
      {
        id: 3,
        name: "부족"
      }
    ],
    "28": [{
        id: -1,
        name: "전체"
      }, {
        id: 0,
        name: "좋음"
      },
      {
        id: 1,
        name: "보통"
      },
      {
        id: 2,
        name: "나쁨"
      },
      {
        id: 3,
        name: "매우나쁨"
      }
    ],
    "8": [{
      id: -1,
      name: "전체"
    },{
      id: 0,
      name: "정상"
    },{
      id: 2,
      name: "주의"
    },{
      id: 3,
      name: "위험"
    }
    ],    
}

const SENSOR_TYPES = [{
    id: -1,
    name: "전체"
  }, {
    id: 1,
    name: "배터리(V)"
  },
  {
    id: 12,
    name: "수분량(%)"
  },
  {
    id: 28,
    name: "초미세먼지(ug/㎥)"
  }
]

const SRDS_SENSOR_TYPES = [ {
  id: -1,
  name: "전체"
}, { 
  id: 8,
  name: "위험등급"
}, 
]

const CLOSE_TYPES = [{
    id: -1,
    name: "전체"
  }, {
    id: 0,
    name: "발생"
  },
  {
    id: 1,
    name: "종료"
  }
]


export {
    EVENT_TABLE_OPTIOINS,
    EVENT_TABLE_COLUMS,
    SRDS_EVENT_TABLE_COLUMS,
    FUMAX_EVENT_TABLE_COLUMNS ,
    LEVEL_TYPES,
    SENSOR_TYPES,
    SRDS_SENSOR_TYPES,
    CLOSE_TYPES,
}
