<template>
<div>
  <CCard>
    <CCardHeader>
      <strong>이벤트 검색 조건</strong>
    </CCardHeader>
    <CCardBody>
      <div class="row">
        <div class="col-sm-2 col-lg-2">
          <label for="inputState" class="col-form-label">일자</label>
        </div>
        <div class="col-sm-2 col-lg-2">
          <date-picker v-model="dates" type="date" range placeholder="Select date range"></date-picker>
        </div>
      </div>
      <div class="search-marin"></div>

      <div class="row">
        <div class="col-sm-2 col-lg-2">
          <label for="inputState" class="col-form-label">센서타입</label>
        </div>
        <div class="col-sm-3 col-lg-3">
          <div class='input-group'>
              <select class='form-control' style='display:inline-block;height:32px' v-model='sensor' @change="onChange($event)">
                <option v-for="sensor in sensorTypes" :value="sensor.id" v-bind:key="sensor.id">{{sensor.name}} </option>
              </select>
          </div>
        </div>
      </div>
      <div class="search-marin"></div>

      <div class="row">
        <div class="col-sm-2 col-lg-2">
          <label for="inputState" class="col-form-label">등급</label>
        </div>
        <div class="col-sm-3 col-lg-3">
          <div class='input-group'>
            <select class='form-control' style='display:inline-block;height:32px' v-model='level'>
              <option v-for="level in levelTypes" :value="level.id" v-bind:key="level.id">{{level.name}} </option>
            </select>
          </div>
        </div>
      </div>
      <div class="search-marin"></div>
      <div class="row">
        <div class="col-sm-2 col-lg-2">
          <label for="inputState" class="col-form-label">종료 여부</label>
        </div>
        <div class="col-sm-3 col-lg-3">
          <select class='form-control' style='display:inline-block;height:32px' v-model='close'>
            <option v-for="close in closeTypes" :value="close.id" v-bind:key="close.id">{{close.name}} </option>
          </select>
        </div>
      </div>

    </CCardBody>

    <CCardFooter>
      <CRow class="text-center">
        <CCol col="12" xl="12">
          <CButton color="warning" class="float-right" @click="saveExcel()" :disabled="enableExcel == false">
            엑셀저장
          </CButton>
          <CButton color="success" class="float-right mr-1" @click="queryEvent()">
            조회
          </CButton>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>

  <CCard>
    <CCardBody class="pt-1">
      <div id="toolbar" class="pt-2">
        <strong class="pl-1 float-left mr-2">이벤트 목록</strong>
        <CLink href="#" class="card-header-action btn-close float-left" v-on:click="onCloseEvent()" :disabled="selected_row == null">
          <CIcon class="mb-1" name="cil-x-circle"/>
        </CLink>
      </div>
      <v-table id="eventTable" 
        data-toolbar="#toolbar" 
        :columns="columns" 
        :data="data" 
        :options="options"
        @on-check="onCheckSite" 
        @on-uncheck="onUncheckSite"
      ></v-table>
    </CCardBody>
  </CCard>

  <Progress
      ref="progressDialog"
      title="확인"
  />
  <Notify ref="notifyDialog"/>
  <Confirm
      ref="confirmDialog"
      @hide="hideModal"
  />

</div>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm'
import XLSX from 'xlsx';

import { EVENT_TABLE_OPTIOINS, EVENT_TABLE_COLUMS } from "@/constants/eventSettings"
import { LEVEL_TYPES, SENSOR_TYPES, CLOSE_TYPES } from "@/constants/eventSettings"
import { IMAGES } from "@/constants/constant"
import utils from '@/services/utils'

import Progress from '@/views/popups/Progress';
import { faThList } from '@fortawesome/free-solid-svg-icons';

// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';

export default {
  name: 'EventManage',
  components: {
    BootstrapTable: BootstrapTable,
    // DatePicker: DatePicker,
    Progress: Progress
  },
  props: {
    prefix: {
      type: String,
      default: ''
    }
  },
  created() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites

    this.dates.push(new Date(moment().add(-1, 'month').format('YYYY-MM-DD')));
    this.dates.push(new Date(moment().add(1, 'day').format('YYYY-MM-DD')));

    this.options.clickToSelect = true;
    this.options.singleSelect = true;

    this.setFormatter('created_at', utils.dateFormatter);
    this.setFormatter('is_closed', this.closeFormatter);
    this.setFormatter('level', this.imageFormatter2);
    this.setFormatter('site', this.siteFormatter);
    this.setFormatter('device_name', this.nameFormatter);
    this.setFormatter('sensor_type', this.sensorTypeFormatter);
    this.queryEvent();
  },
  data () {
    var table_options = _.cloneDeep(EVENT_TABLE_OPTIOINS);
    table_options.search = true;
    return {
      user: null,
      sites: null,
      options: table_options,
      columns: _.cloneDeep(EVENT_TABLE_COLUMS),
      data: [],
      dates: [],
      sensor: -1,
      level: -1,
      close: 0,
      enableExcel: false,
      levelTypes: LEVEL_TYPES[-1],
      selected_row: null
    }
  },
  computed: {
    sensorTypes() { return SENSOR_TYPES },
    closeTypes() { return CLOSE_TYPES },
  },
  methods: {
    setFormatter(field, formatter) {
      var column = _.find(this.columns, { field: field });
      if (column) {
        column.formatter = formatter;
      }
    },
    getDeviceUrl(row) {
      return '#/{0}device/{1}'.format(this.prefix, _.get(row, "device_guid"));
    },
    nameFormatter(value, row, index) {
      return "<a href='" + this.getDeviceUrl(row) + "'>" + _.get(row, "device_name") + "</a>";
    },
    getSiteName(site_guid) {
      var site = this.sites.filter(function(e) {
        return e.guid == site_guid;
      })[0];
      return _.get(site, "name", "");
    },
    siteFormatter(value, row, index) {
      return "<a href='#/{0}sites/{1}'>{2}</a>".format(this.prefix, value, this.getSiteName(value));
    },
    closeFormatter(value, row, index) {
      return value == true ? 'Y' : 'N';
    },
    imageFormatter2(value, row, index) {
      var imgsrc;
      Object.keys(IMAGES).some(function(key, i) {
        if ((row.sensor_type % 64) === IMAGES[key].type && row.level === IMAGES[key].level) {
          imgsrc = IMAGES[key].name;
          return true;
        }
      });
      if(imgsrc == undefined)
          imgsrc = "img/humidity-lack.png";

      return '<img src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / > '
    },
    sensorTypeFormatter(value, row, index) {
      return utils.getSensorTypeName(row.sensor_type, row.sensor_id, true);
    },
    onCheckSite(row, $element) {
      this.selected_row = row;
    },
    onUncheckSite(row, $element) {
      this.selected_row = null;
    },
    onCloseEvent() {
      // console.log('Event::onCloseEvent selected_row:', JSON.stringify(this.selected_row,null,2))
      if (this.selected_row === null) return;

      let message = '이벤트 : ' + this.selected_row.device_name + ' 알림을 종료 하시겠습니까?'
      this.$refs.confirmDialog.show(message, '이벤트 종료', undefined, 'delete');
    },
    hideModal(params) {
      if (_.isUndefined(params)) {
        return; // cancel command
      }
      switch (params.name) {
        case 'Confirm':
          if (params.which === 'delete' && params.data === 'confirm') {
            this.removeEvent(this.selected_row)
          }
          break;
        default:
          break
      }
    },
    onChange(event) {
      var index = event.target.value
      this.levelTypes = LEVEL_TYPES[index]
    },
    saveExcel() {
      var self = this;
      var write = function() {
        function levelTypeFormatter(type, level) {
          var result = "";
          switch (type) {
            case 1:
              if (level == 0) result = "좋음"
              else if (level == 1) result = "보통"
              else if (level == 2) result = "주의"
              else if (level == 3) result = "경고"
              break;
            case 2:
            case 12:
              if (level == 0) result = "정상"
              else if (level == 1) result = "과다"
              else if (level == 2) result = "주의"
              else if (level == 3) result = "부족"
              break;
            case 28:
              if (level == 0) result = "좋음"
              else if (level == 1) result = "보통"
              else if (level == 2) result = "나쁨"
              else if (level == 3) result = "매우"
              break;
          }
          return result;
        }
        function makeFileName(name) {
          var from = moment(self.dates[0]).format('YYYY-MM-DD');
          var to = moment(self.dates[1]).format('YYYY-MM-DD');
          return name + "_" + from + "-" + to;
        }

        var alarmData = $('#eventTable').bootstrapTable('getData');
        var r = [];

        var column_width = [20, 15, 20, 5, 5, 10, 25, 30];
        var wscols = [];
        column_width.forEach(function(item) {
          wscols.push({
            "wch": item
          })
        });

        alarmData.forEach(function(data, i) {
          var row = {
            '발생일시': utils.dateFormatter(data.created_at),
            '사이트명': self.getSiteName(data.site),
            '디바이스명': data.device_name,
            '상태': levelTypeFormatter(data.sensor_type, data.level),
            '종료': data.is_closed ? "종료" : "발생",
            '센서타입': utils.getSensorTypeName(data.sensor_type, data.sensor_id, true),
            '상세': data.description,
            '위치': data.device_address
          }
          r.push(row);
        })

        var ws = XLSX.utils.json_to_sheet(r);
        ws['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        var prefix = "이벤트";
        XLSX.utils.book_append_sheet(wb, ws, prefix);
        var filename = makeFileName(prefix, false);
        XLSX.writeFile(wb, filename + ".xlsx");
      }

      this.$refs.progressDialog.show({message: '요청 데이터를 저장중입니다.', counter:50, callback: write})
    },
    removeEvent(event) {
      var self = this;
      loopback
        .upsertWithWhere('/events', {guid: event.guid}, {is_closed: true})
        .then((res) => {
          let message = "{0} : '{1}\' 알림을 종료했습니다.".format(self.selected_row.device_name, res.description);
          this.$refs.notifyDialog.show(message, 2000);
          // console.log('Event::removeEvent : ', message)
          $('#eventTable').bootstrapTable('remove', {
            field: 'guid',
            values: [event.guid]
          });
        })
        .catch((err) => {
          console.log('removeEvent error: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
    queryEvent() {
      var self = this;
      var data = {
        from: moment(this.dates[0]).format('YYYY-MM-DD'),
        to: moment(this.dates[1]).format('YYYY-MM-DD')
      }

      if (this.sensor !== -1) data['sensor_type'] = this.sensor;
      if (this.level !== -1) data['level'] = this.level;
      if (this.close !== -1) data['is_closed'] = this.close;

      this.$store.dispatch('rest/method', {model:'events', method:'getAlarmList', data:data})
        .then((res) => {
          self.data = [];
          res.forEach(function(row, index) {
            if(row.service_type === "sis"){
              self.data.push({
                created_at: row.created_at,
                description: row.description,
                service_type: row.service_type,
                sensor_type: row.sensor_type,
                sensor_id: row.sensor_id,
                is_closed: row.is_closed,
                level: row.level,
                device_guid: row.device_guid,
                device_name: _.get(row, "device.name"),
                device_address: _.get(row, "device.address"),
                site: _.get(row, "device.site_guid"),
                guid: row.guid,
              });
            }
          })
          self.enableExcel = self.data.length > 0 ? true : false;
        })
        .catch((err) => {
          console.log('queryEvent event: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
  }
}
</script>

<style scoped>
.search-marin {
 margin: 2px 0 2px;
}
</style>
